import React from 'react';
import Slick from "react-slick";
import { Link } from 'prismic-reactjs';
import linkResolver from '../linkResolver';

class Slider extends React.Component {
  render() {
    let settings = {
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <Slick {...settings}>
       { this.props.children }
      </Slick>
    );
  }
}

class SliderItem extends React.Component {
  render() {

      let link_url = this.props.link ? Link.url(this.props.link, linkResolver) : '';

      let link_target = this.props.link.target ?
          {
              target: this.props.link.target,
              rel: "noopener"
          } : { };
      
      return (
      <div className="slider-item">
        <img className="photo" 
          srcSet={`${ this.props.images.desktop } 1700w,
                   ${ this.props.images.mobile  } 540w`}
          sizes="(max-width: 540px) 540px,
                 1700px"
          src={ this.props.images.desktop }
          alt={ this.props.alt }
        />
        <div className="slider-overlay">
          <div className="content">
            {
              link_url ?
              <a href={ link_url } { ...link_target }>
                { this.props.children }
              </a> : 
              this.props.children
            }
          </div>
        </div>
      </div>
    );
  }
}


export {
  Slider,
  SliderItem
}