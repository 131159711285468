import React from 'react';
import { Row } from 'reactstrap';
import { Link } from 'prismic-reactjs';
import linkResolver from '../linkResolver';

class CalloutGrid extends React.Component {

    render() {
        const childElements = this.props.elements.map( (e, index) => {
        
            let link_url = e.anchor_link ? 
                    `#${e.anchor_link}` :
                e.standard_link ?  
                    Link.url(e.standard_link, linkResolver) :
                    '';

            let link_target = e.standard_link.target ? 
                { 
                    target: e.standard_link.target,
                    rel: "noopener"
                } : { };
                
            return (       
                <div key={ index } className='col-sm-6 col-md-6 col-lg-3 my-3'>
                    <div className={`callout callout-${e.color}`}>
                        <a href={ link_url } { ...link_target }>
                            <div className='callout-image'>
                                { e.callout_image && e.callout_image.url ? 
                                <img src={ e.callout_image.url } alt={ e.title1 } /> : "" }
                            </div>
                            <div className="title">
                                { e.title1 }
                            </div>
                        </a>
                    </div>
                </div>
            );
        });

        return (
            <Row className="justify-content-center">
             { childElements }  
            </Row>
        );
    }
}

export default CalloutGrid
