import React from 'react';
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import {Slider, SliderItem } from '../components/Slider';
import CalloutGrid from '../components/CalloutGrid';
import welcome_box_img from '../components/layout/images/white_dots.png';
import power_future_img from '../components/layout/images/Powering-Our-Future-Banner1.png';
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import linkResolver from '../linkResolver';
import { Button, Col, Container, Row } from 'reactstrap';

class IndexPage extends React.Component {
    scroll(){
        document.getElementById('content').scrollIntoView({block: 'start', behavior: 'smooth'});
    }

    scrollTop(){
        document.getElementById('top').scrollIntoView({block: 'start', behavior: 'smooth'});
    }

    loadExternalPage() {
        if( typeof window !== `undefined` ) window.open('https://poweringourfuture.ca/');
    }

  render() {
    const node = this.props.data.prismicHomePage;
    const content = JSON.parse(node.dataString);
    
    const sliderItems = content.slides.map( (slide, index) => {                  
      const images = {
        desktop: slide.image && slide.image.url,
        mobile: slide.image.mobile && slide.image.mobile.url
      };      
      return (
        <SliderItem key={ index } link={ slide.link } images={ images } alt={ slide.title1} >
          <h1>{ slide.title1 }</h1>
          <h4>{ slide.body1 }</h4>
        </SliderItem>
      )
    });

    return (
      <Layout>
         <Helmet>      
          <meta http-equiv="Cache-Control" content="public, max-age=0, must-revalidate" />
          <meta http-equiv="Pragma" content="no-cache"/>
        </Helmet>
        <div className="homepage-banner">
            <Slider>
              { sliderItems }
            </Slider>
            <div className="banner-slidedown">
                <a href="javascript:void(0);" onClick={this.scroll}><i className="c-backtop-1-icon fa fa-chevron-circle-down fa-custom"></i></a>
            </div>
        </div>

        <Container id="content" className="my-5">
          <CalloutGrid elements={ content.callouts }></CalloutGrid>
        </Container>

        <div className="external-site-banner">
            <img src={power_future_img} alt="Powering Our Future" />
            <Row className="external-site-overlay">
                <Col>
                    <Button className='external-site-btn' size="lg" onClick={ this.loadExternalPage }>PLUG IN!</Button>
                </Col>
            </Row>
        </div>

        <div className="welcome-box"><img src={welcome_box_img} alt="Welcome" /> </div>
        <div className="welcome-box-inset">
          <Row className="welcome-box-content">
            <Col md="5" className="welcome-box-header align-self-center">
              <h1>{ content.title }</h1>
            </Col>
            <Col md="7" className="welcome-box-text align-self-center">
              { RichText.render(content.body, linkResolver )}
            </Col>
          </Row>
        </div>
          <div className="c-backtop-1">
              <a href="javascript:void(0);" onClick={this.scrollTop}><i className="c-backtop-1-icon fa fa-chevron-circle-up fa-custom"></i></a>
          </div>
      </Layout>
    );
  }
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    prismicHomePage {
      id
      uid
      prismicId
      dataString 
    }
  }
`
